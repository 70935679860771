import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import Form, { Section, Title } from '../../../components/Form';
import VehicleTable from './VehicleTable';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { VehicleAPI } from '../../../api';
import { connect } from 'react-redux';
import config from 'config/config';

const StyledRadioGroup = styled(RadioGroup)(() => ({
  flexDirection: 'row',
  marginLeft: 40,
}));

const DivToolbar = styled('div')(() => ({
  minHeight: 50,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const DivInfoText = styled('div')(() => ({
  display: 'flex',
  fontWeight: 900,
}));

const READY = 'ready';
const DONE = 'done';
const UPLOAD = 'upload';
const CANCEL = 'cancel';

const UPDATE = 'update';
const REPLACE = 'replace';
const APPEND = 'append';

const VEHICLE_FIELDS = [
  'vehicle_id',
  'seats',
  'description',
  'disposed_date',
  'max_weight_kg',
  'client_id',
  'fuel_rate_source',
  'transmission_type',
  'variant',
  'engine_cylinders',
  'auxiliary_fuel_method',
  'min_weight_kg',
  'engine_displacement_l',
  'registration',
  'registration_state',
  'division',
  'ref_fuel_rate_driving_l_100km',
  'make',
  'fuel_type',
  'vin',
  'auxiliary_fuel_use',
  'model',
  'auxiliary_fuel_percentage',
  'auxiliary_fuel_rate_lhr',
  'auxiliary_safe_harbour_override',
  'ref_fuel_rate_idling_l_hr',
  'acquired_date',
  'load_type',
  'ftc_on_road',
  'ref_id',
  'model_year',
  'disabled',
  'category',
  'client_vehicle_id',
  'gvm_tonnes',
  'gcm_tonnes',
  'registration_cost_details',
  'pbs_approved',
  'registration_charge_code',
  'registration_end_date',
  'number_of_axles',
  'device_type',
  // RUC-specific fields
  ...(config.isBarton ? ['ruc_vehicle_type', 'con_ops'] : []),
];

// returns a prism vehicle_id (or undefined).
// First tries to match by vehicle_id.
// Second tries to match by client_vehicle_id (takes first match)
const matchVehicle = (vehicle, vehicles) => {
  if (vehicle.vehicle_id && vehicles.find(v => v.vehicle_id === vehicle.vehicle_id)) {
    return vehicle.vehicle_id; // matched exactly by vehicle_id;
  }
  if (vehicle.client_vehicle_id) {
    const v = vehicles.find(v => v.client_vehicle_id === vehicle.client_vehicle_id);
    if (v !== undefined) {
      return v.vehicle_id;
    }
  }
  return undefined;
};

const matchedVehicleIds = (newVehicles, vehicles) => {
  return newVehicles.map(v => matchVehicle(v, vehicles));
};

const unmatchedVehicleIds = (matchedIds, vehicles) => {
  return vehicles.reduce((list, v) => {
    if (!matchedIds.find(id => id === v.vehicle_id)) {
      list.push(v.vehicle_id);
    }
    return list;
  }, []);
};

class UploadReviewForm extends React.Component
{
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    fileName: PropTypes.string,
    newVehicles: PropTypes.array.isRequired,
    vehicles: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    clientId: PropTypes.string,
  }

  state = {
    action: READY,
    method: UPDATE,
    cancelled: false,
    status: [],
    progress: 0,

  }

  constructor(props) {
    super(props);
    Object.assign(this.state, this.computeDeltas());
  }

  componentDidUpdate(prevProps) {
    if (this.props.newVehicles !== prevProps.newVehicles ||
        this.props.vehicles !== prevProps.vehicles) {
      this.setState(this.computeDeltas());
    }
  }

  computeDeltas = () => {
    const matchedIds = matchedVehicleIds(this.props.newVehicles, this.props.vehicles);
    const unmatchedIds = unmatchedVehicleIds(matchedIds, this.props.vehicles);
    return {
      matchedIds,
      unmatchedIds,
    };
  }

  removeVehicles = async (vehicleIds, cb=null, i=0) => {
    if (i < vehicleIds.length) {
      const vehicleId = vehicleIds[i];
      const result = await VehicleAPI.remove(this.props.clientId, vehicleId);
      if (cb) await cb(vehicleId, result, i, vehicleIds.length);
      return this.removeVehicles(vehicleIds, cb, i+1);
    }
  };

  saveVehicle = async (vehicles, vehicleIds, cb=null, i=0) => {
    if (i < vehicles.length && this.state.action !== CANCEL) {
      const vehicleId = vehicleIds[i];
      const vehicle = this.filterFields(vehicles[i]);
      let result;
      try {
        if (!vehicleId) {
          result = await VehicleAPI.create(this.props.clientId, vehicle);
          // create does not return a vehicle_id
        } else {
          result = await VehicleAPI.update(this.props.clientId, vehicleId, vehicle);
        }
      } catch (err) {
        result = err.response;
      }
      if (cb) await cb(vehicles[i], result, i, vehicles.length);
      return this.saveVehicle(vehicles, vehicleIds, cb, i+1);
    }
  }

  afterSave = (vehicle, result, i) => {
    this.setState((state) => {
      state.status[i] = (result !== undefined) && (result.status_code === 200);
      state.progress += 1;
      return state;
    });
  }

  afterDelete = () => {
    this.setState((state) => {
      state.progress += 1;
      return state;
    });
  }

  matchIds = () => {
    let updateIds;
    let deleteIds;
    if (this.state.method === REPLACE) {
      updateIds = this.state.matchedIds;
      deleteIds = this.state.unmatchedIds;
    } else if (this.state.method === UPDATE) {
      updateIds = this.state.matchedIds;
      deleteIds = [];
    } else if (this.state.method === APPEND) {
      updateIds = Array(this.props.newVehicles.length); // all elements undefined
      deleteIds = [];
    }
    return [updateIds, deleteIds];
  }

  filterFields = (vehicle) => {
    return VEHICLE_FIELDS.reduce((o,f) => {
      if (f in vehicle) {
        if (f === 'registration_cost_details' && typeof vehicle[f] === 'string' && vehicle[f] !== null) {
          try {
            o[f] = JSON.parse(vehicle[f]);
          } catch (e) {
            console.error(`Error parsing JSON for field ${f}: ${e}`);
            o[f] = vehicle[f];
          }
        } else {
          o[f] = vehicle[f];
        }
      }
      return o;
    }, {});
  }

  onSave = async () => {
    if (this.props.newVehicles) {
      const [updateIds, deleteIds] = this.matchIds();

      const opCount = updateIds.length + deleteIds.length;
      this.setState({action: UPLOAD, progress: 0, progressRange: opCount});
      await this.saveVehicle(
        this.props.newVehicles,
        updateIds,
        this.afterSave);
      if (this.state.method === REPLACE && this.state.action !== CANCEL) {
        await this.removeVehicles(deleteIds, this.afterDelete);
      }
      this.setState((state) => {
        this.props.refresh();
        // if state is cancelled, leave it alone
        if (state.action !== CANCEL) return {action: DONE};
      });
    }
  }

  onCancel = () => {
    this.setState({action: CANCEL});
    this.props.refresh();
  }

  onClose = () => {
    this.setState({action: CANCEL});
    this.props.onClose();
  }

  onChangeMethod = (event) => {
    this.setState({method: event.target.value});
  }

  infoText = () => {

    const {action} = this.state;
    if (action === DONE) return 'Done';
    if (action === CANCEL) return 'Cancelled';

    const [updateIds, deleteIds] = this.matchIds();
    const updateCount = updateIds.reduce((count, id) => count + (id ? 1 : 0), 0);
    const addCount = updateIds.length - updateCount;
    const deleteCount = deleteIds.length;

    let parts = [];
    if (addCount > 0) parts.push(`Add ${addCount}`);
    if (updateCount > 0) parts.push(`Update ${updateCount}`);
    if (deleteCount > 0) parts.push(`Delete ${deleteCount}`);
    if (parts.length > 0) {
      return parts.join(', ');
    } else {
      return 'No changes';
    }
  }

  actionButton = () => {
    const { action } = this.state;
    if (action === READY) return (
      <Button variant="contained" color="primary" onClick={() => this.onSave()}>
        Continue
        <DoneIcon/>
      </Button>
    );
    if (action === UPLOAD) return (
      <Button variant="contained" color="secondary" onClick={() => this.onCancel()}>
        Cancel
        <CloseIcon/>
      </Button>
    );
    return (
      <div/>
    );
  }

  renderToolbar = () => {
    const { action, method } = this.state;
    const methodDisabled = action !== READY;
    return (
      <DivToolbar>
        <div>
          <FormControl component="fieldset">
            <StyledRadioGroup
              aria-label="method"
              name="method"
              value={method}
              onChange={this.onChangeMethod}
            >
              <FormControlLabel
                value={UPDATE}
                control={<Radio color="primary" disabled={methodDisabled} />}
                label="Update"
                labelPlacement="end"
              />
              <FormControlLabel
                value={REPLACE}
                control={<Radio color="primary" disabled={methodDisabled} />}
                label="Replace"
                labelPlacement="end"
              />
              <FormControlLabel
                value={APPEND}
                control={<Radio color="primary" disabled={methodDisabled} />}
                label="Append"
                labelPlacement="end"
              />
            </StyledRadioGroup>
          </FormControl>
        </div>
        <DivInfoText>{this.infoText()}</DivInfoText>
        {this.actionButton()}
      </DivToolbar>
    );
  }

  render() {
    const { fileName, newVehicles } = this.props;
    const { status, progress, progressRange } = this.state;
    const progressPercent = progressRange ? 100 * progress / progressRange : 0;
    return (
      <Form>
        <div align="left">
          <IconButton onClick={this.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Title>Bulk Vehicle Upload</Title>
        {this.renderToolbar()}
        <div style={{ height: 5 }}>
          <LinearProgress variant="determinate" value={progressPercent} />
        </div>
        <Section title={fileName}>
          <VehicleTable
            vehicles={newVehicles.map((v, i) =>
              Object.assign({ status: status[i] }, v)
            )}
          />
        </Section>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)((UploadReviewForm));
