import * as AuthStore from './auth';
import * as ClientStore from './clients';
import * as JobsStore from './jobs';
import * as LoadingStore from './loading';
import * as RefsStore from './refs';
import * as ReportFilesStore from './reportFiles';
import * as SourcesStore from './sources';
import * as UserStore from './users';
import * as VehicleStore from './vehicles';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import { AuthAPI } from '../api';
import auth from './auth';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import clients from './clients';
import jobs from './jobs';
import loading from './loading';
import persistReducer from './persistReducer';
import { persistStore } from 'redux-persist';
import refs from './refs';
import reportFiles from './reportFiles';
import sources from './sources';
import thunk from 'redux-thunk';
import users from './users';
import vehicles from './vehicles';

const reducers = combineReducers({
  auth,
  clients,
  users,
  refs,
  vehicles,
  loading,
  sources,
  jobs,
  reportFiles,
});

const rootReducer = (state, action) => {
  // drop user-specific saved state if user changes.
  if (action.type === 'auth/LOGIN' &&
      state.auth &&
      state.auth.userId !== action.profile.user_id) {
    state = {
      ...state,
      clients: undefined,
      auth: undefined,
      users: undefined,
      vehicles: undefined,
    };
  }
  return reducers(state, action);
};

const tokenHydrate = () => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    if (action.payload && action.payload.auth && action.payload.auth.token) {
      AuthAPI.setBearerToken(action.payload.auth.token);
    }
  }
  return next(action);
};

/* 
 * If Redux DevTools Chrome extension is loaded, add it as an enhancer.
 * See: https://github.com/reduxjs/redux-devtools
 * and: https://chromewebstore.google.com/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
 *
 * REVISIT - disable this extension on prod once we are sure the current set of changes have no ill effects.
 */
const enhancers = [];
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const middleware = [thunk, tokenHydrate];
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const initialState = {};
const persistedReducer = persistReducer('root', rootReducer, {stateReconciler: autoMergeLevel2});
const store = createStore(persistedReducer, initialState, composedEnhancers);
const persistor = persistStore(store);

export {
  persistor,
  AuthStore,
  ClientStore,
  UserStore,
  RefsStore,
  VehicleStore,
  LoadingStore,
  SourcesStore,
  JobsStore,
  ReportFilesStore,
};
export default store;
