import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography} from '@material-ui/core';
import { AnalyticsAPI } from '../../../api';
import TimeSeriesBarChart from '../../../components/analytics/TimeSeriesBarChart.js';


const PREFIX = 'Stats';

const classes = {
  container: `${PREFIX}-container`,
  component: `${PREFIX}-component`,
  subText: `${PREFIX}-subText`,
  subTextContainer: `${PREFIX}-subTextContainer`,
  cardSubText: `${PREFIX}-cardSubText`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    fontSize: '1.2rem',
    flexWrap: 'wrap',
    width: '100%',
    // paddingTop: '1rem',
  },
  [`& .${classes.component}`]: {
    width: '20rem',
    minWidth: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '0.5rem',
    // width: '11.5rem',
    marginTop: '0.5rem',
    marginRight: '1.3rem',
    marginBottom: '0.5rem',
  },
  [`& .${classes.subText}`]: {
    color: '#6B7280',
    fontSize: '0.7rem',
    marginTop: '5px',
  },
  [`& .${classes.subTextContainer}`]: {
    position: 'relative',
    left: 0,
  },
  [`& .${classes.cardSubText}`]: {
    paddingBottom:'0.5rem',
    fontSize: '0.8rem',
    justifyContent:'flex-start',
    color: '#737373',
    height:'2rem'
  },
}));


class Stats extends React.Component {

  static propTypes = {
    clientId: PropTypes.string,
  }

  state = {
    data: undefined,
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { clientId } = this.props;
    if (clientId) {
      AnalyticsAPI.getDailyMetrics(clientId)
        .then(response => this.setState({data: response.payload.slice(1).slice(-30)}));  // hack to limit to last 30 days, add to API
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId) {
      this.getData();
    }
  }

  renderEmptyState = (classes) => {
    return (
      <p className={classes.subText}>
        Retrieving stats...
      </p>
    );
  }

  render() {
    const { data } = this.state;
    if (!data) return this.renderEmptyState(classes);
    return (
      <Root className={classes.container}>
        <div className={classes.component}>
          <div className={classes.subTextContainer}>
            <Typography className={classes.cardSubText} align={'left'}>Total KM Travelled</Typography>
          </div>
          <TimeSeriesBarChart
            data={data}
            xAxisKey="date"
            yAxisKey="distance_total"
            yAxisLabel='KMs'
            colourChoice={1}
          />
        </div>
        <div className={classes.component}>
          <div className={classes.subTextContainer}>
            <Typography className={classes.cardSubText} align={'left'}>Active Vehicle Count</Typography>
          </div>
          <TimeSeriesBarChart
            data={data}
            xAxisKey="date"
            yAxisKey="vehicle_count"
            yAxisLabel='Vehicles'
            colourChoice={2}
          />
        </div>
        <div className={classes.component}>
          <div className={classes.subTextContainer}>
            <Typography className={classes.cardSubText} align={'left'}>Fuel Use Estimate</Typography>
          </div>
          <TimeSeriesBarChart
            data={data}
            xAxisKey="date"
            yAxisKey="fuel_total"
            yAxisLabel='Litres'
            colourChoice={3}
          />
        </div>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientId: state.clients.selectedItemId,
  };
};

export default connect(mapStateToProps)((Stats));
